import type { DataObject, ItemModel } from 'o365-dataobject';
// import { computed } from "vue";
// import { getDataObjectById } from 'o365.vue.ts'; 

// const dsPVisibleFields = getDataObjectById("dsPVisibleFields");

function configurableFieldExists<T extends ItemModel = ItemModel>(dataObject: DataObject<T>, field: string): boolean {
    const index = dataObject.data.findIndex((f: any) => f.FieldName === field);
    return index !== -1;
}

function configurableFieldIsVisible<T extends ItemModel = ItemModel>(dataObject: DataObject<T>, field: string): boolean {
    const index = dataObject?.data.findIndex((f: any) => f.FieldName === field);

    if (index === -1) {
        return true;
    }

    const row = dataObject.data[index];
    return row.Visible;
}

function configurableFieldIsMandatory<T extends ItemModel = ItemModel>(dataObject: DataObject<T>, field: string): boolean {
    return dataObject?.data.some((f: any) => f.FieldName === field && f.Mandatory);
}

function getMissingMandatoryFields<T extends ItemModel = ItemModel>(fieldsDataObject: DataObject<T>, workflowDataObject: any): string[] {
    return fieldsDataObject?.data.filter((f: any) => f.MandatoryAtClose && !workflowDataObject.current[f.FieldName]?.trim()).map((f: any) => f.FieldName);
}

export { configurableFieldIsMandatory, configurableFieldIsVisible, getMissingMandatoryFields, configurableFieldExists }